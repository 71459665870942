<template>
  <div>
    <Header />
    <Breadcrumbs title="Compagnie" />
    <section class="section-b-space">
      <div class="container">
        <div class="row">
          <b-card no-body v-bind:class="'dashboardtab'">
            <b-tabs pills card vertical>
              <b-tab title="Fondation" active>
                <b-card-text>
                  <div class="dashboard-right">
                    <div class="dashboard">
                      <div class="page-title">
                        <h2>FONDATION</h2>
                      </div>
                      <div class="welcome-msg">
                        <p>ASCENSION GROUP est fondée en 2016 par un groupe d’entrepreneurs haïtiens dynamiques
                          et progressistes qui s’engagent à apporter leurs pierres dans la construction d’une nouvelle
                          Haïti où chacun peut vivre dans un minimum de confort et de bien-être qui honore la dignité
                          humaine.</p>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab title="Vision & Valeurs">
                <b-card-text>
                  <div class="dashboard-right">
                    <div class="dashboard">
                      <div class="page-title">
                        <h2>VISION & VALEURS</h2>
                      </div>
                      <div class="welcome-msg">
                        <p>Ascension Group est une entreprise haïtienne citoyenne qui œuvre au bien-être individuel et
                          collectif des haïtiens. Elle s’enracine dans l’idée selon laquelle l’intérêt de toute organisation
                          sociale est la réalisation de l’homme dans le respect de sa dignité. Ainsi, s’inscrit-elle dans une
                          visée « mouniste » et fait du respect de la dignité humaine, de la solidarité, du bien-être et du
                          sens de responsabilité ses valeurs fondamentales.</p>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab title="Mission">
                <b-card-text>
                  <div class="dashboard-right">
                    <div class="dashboard">
                      <div class="page-title">
                        <h2>MISSION</h2>
                      </div>
                      <div class="welcome-msg">
                        <p>Notre mission est de vous aider à acquérir votre liberté financière par la mise en place de votre
                          propre réseau de promotion et de vente.</p>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab title="Partenaires">
                <b-card-text>
                  <div class="dashboard-right">
                    <div class="dashboard">
                      <div class="page-title">
                        <h2>PARTENAIRES</h2>
                      </div>
                      <div class="welcome-msg">
                        <p>Nous avons des partenariats commerciaux avec des entreprises et organisations du secteur
                          productif haïtien ainsi qu’avec certaines entreprises du secteur import-export. Elles nous
                          alimentent en produits bruts ou finis. Nous sommes ouverts au partenariat avec toutes
                          entreprises (petites, moyennes ou grandes) qui le souhaitent moyennant le respect des
                          conditions de qualité, de standardisation et de légalité.</p>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>
import Header from "../../components/header/appHeader";
import Footer from "../../components/footer/appFooter";
import Breadcrumbs from "../../components/widgets/breadcrumbs";
export default {
  components: {
    Header,
    Footer,
    Breadcrumbs
  }
}
</script>
